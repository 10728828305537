import React from 'react';
import classes from './Checkbox.module.scss';
import { CheckboxProps } from './Checkbox.types';
import ActiveCheckbox from '../../assets/Icons/ActiveCheckbox';
import PartialCheckbox from '../../assets/Icons/PartialCheckbox';
import DisabledCheckbox from '../../assets/Icons/DisabledCheckbox';
import UncheckedCheckbox from '../../assets/Icons/UncheckedCheckbox';

/**
 * Secondary description on Storybook Docs. I can have multiple lines and
   bullets!
  - The primary component has:
    - CSS modules
    - Tailwind styles
    - CSS variables
  - To make sure all different CSS channels are working
  */
const Checkbox: React.FC<CheckboxProps> = ({
  children,
  label,
  name,
  value,
  disabled,
  className = '',
  extraProps = {},
  isPartial: isIndeterminate,
  ...props
}) => {
  const [isChecked, setIsChecked] = React.useState(extraProps?.input?.checked || false);

  React.useEffect(() => {
    setIsChecked(extraProps?.input?.checked || false);
  }, [extraProps?.input?.checked]);

  const labelProps = {
    htmlFor: value || String(extraProps?.input?.value),
    ...(extraProps.label || {}), // Allow overriding default label props
  };

  const inputProps = {
    type: 'checkbox',
    disabled,
    name,
    value,
    id: value || String(extraProps?.input?.value),
    ...(extraProps.input || {}), // Allow overriding default input props
  };

  const handleChange = (e) => {
    setIsChecked(e.target.checked);
    if (inputProps.onChange) {
      inputProps.onChange(e);
    }
  };

  return (
    <div
      data-testid='checkbox-component'
      className={[classes['checkbox'], disabled ? classes['disabled'] : '', className].join(' ')}
      {...props}
    >
      <input {...inputProps} onChange={handleChange} />

      {isChecked ? (
        <>{!isIndeterminate ? <ActiveCheckbox /> : <PartialCheckbox />}</>
      ) : (
        <>{disabled ? <DisabledCheckbox /> : <UncheckedCheckbox />}</>
      )}
      <label {...labelProps}>{children || label}</label>
    </div>
  );
};

export default Checkbox;
