import React, { TextareaHTMLAttributes } from 'react';
import classes from './Select.module.scss';
import { GroupOption, GroupOptionString, OptionProperty, SelectOption, SelectProps } from './Select.types';
import ReactSelect from 'react-select';
import CreateSelect from 'react-select/creatable';
import InputWrapper from '../InputWrapper/InputWrapper';

import './Select.scss';

type OptionList = SelectOption[] | GroupOption[];

/**
 * Secondary description on Storybook Docs. I can have multiple lines and
 bullets!
 - The primary component has:
 - CSS modules
 - Tailwind styles
 - CSS variables
 - To make sure all different CSS channels are working
 */
const Select: React.FC<SelectProps> = ({
  label,
  placeholder,
  required,
  disabled,
  name,
  helpText,
  showHelpIcon = false,
  errors,
  onChange,
  onBlur,
  value,
  size = 'default',
  isClearable = false,
  isSearchable = false,
  isMulti = false,
  onCreate,
  formatCreateLabel,
  options,
  formatGroupLabel,
  getOptionLabel,
  formatOptionLabel,
  components,
  helpIconTooltipText,
  tooltipProps,
  props,
}) => {
  const hasErrors = errors && errors.length > 0;
  const sizeClass = `select__field--${size}`;
  const selectOptions = convertStringsToOptions(options);
  const change = (opt: SelectOption | SelectOption[]) => {
    if (isMulti && Array.isArray(opt)) {
      return onChange(opt.map((o) => o.value));
    }
    return onChange((opt as SelectOption)?.value || null);
  };
  const Control = onCreate ? CreateSelect : ReactSelect;

  const selectedOption = isMulti
    ? findSelectedOptions(selectOptions, value as string[])
    : findSelectedOption(selectOptions, value as string);

  return (
    <InputWrapper
      label={label}
      required={required}
      name={name}
      helpText={helpText}
      showHelpIcon={showHelpIcon}
      errors={errors}
      helpIconTooltipText={helpIconTooltipText}
      tooltipProps={tooltipProps}
    >
      <Control
        name={name}
        inputId={name}
        className='sui-react-select-control'
        classNamePrefix='sui-react-select'
        classNames={{
          control: () =>
            `${classes['select__field']} ${
              hasErrors ? classes['select__field--error'] : classes['select__field--no-error']
            } ${classes[sizeClass]}`,
        }}
        value={selectedOption}
        onChange={change}
        placeholder={placeholder}
        options={selectOptions}
        isClearable={isClearable}
        isDisabled={disabled}
        isSearchable={isSearchable}
        onCreateOption={onCreate}
        formatCreateLabel={formatCreateLabel}
        isMulti={isMulti}
        onBlur={onBlur}
        formatGroupLabel={
          formatGroupLabel ? (group) => formatGroupLabel(findGroup(selectOptions, group.label)) : undefined
        }
        getOptionLabel={getOptionLabel}
        formatOptionLabel={formatOptionLabel}
        menuPortalTarget={document.body}
        components={components}
        data-testid={`select-${name}`}
        {...props}
      />
    </InputWrapper>
  );
};

function convertStringsToOptions(options: OptionProperty): OptionList {
  if (!options || options.length === 0) return [];

  const firstOption = options[0];

  if (typeof firstOption === 'string') {
    // It's an array of strings, so we convert to options
    return convertToOptions(options as string[]);
  } else if ((firstOption as SelectOption).value) {
    // It's an array of SelectOptions, so we return it
    return options as SelectOption[];
  } else if (typeof (firstOption as GroupOptionString).options[0] === 'string') {
    // It's an array of GroupOptionStrings, so we convert to GroupOptions
    return (options as GroupOptionString[]).map((groupOption) => {
      const newOptions = convertToOptions(groupOption.options as string[]);
      return { label: groupOption.label, options: newOptions };
    });
  } else {
    // It's an array of GroupOptions, so we return it
    return options as GroupOption[];
  }
}

function convertToOptions(options: string[]): SelectOption[] {
  return options.map((o) => ({ value: o, label: o }) as SelectOption);
}

function findSelectedOption(options: OptionList, value: string): SelectOption {
  return toSelectOptions(options).find((o) => o.value == value) || null;
}

function findSelectedOptions(options: OptionList, value: string[]): SelectOption[] {
  if (!value) return null;

  return toSelectOptions(options).filter((o) => (value as string[]).includes(o.value));
}

function toSelectOptions(options: OptionList) {
  if (options.length > 0 && (options[0] as GroupOption).options) {
    return (options as GroupOption[]).flatMap((o) => o.options as SelectOption[]);
  }

  return options as SelectOption[];
}

function findGroup(options: OptionList, label: string): GroupOption {
  return (options as GroupOption[]).find((o) => o.label === label);
}

export default Select;
