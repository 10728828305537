import React from 'react';
import classes from './Label.module.scss';
import { LabelProps } from './Label.types';
import Icon from '../Icon/Icon';
import { ArrowPosition } from '../Tooltip/Tooltip.types';
import Tooltip from '../Tooltip/Tooltip';

/**
 * Secondary description on Storybook Docs. I can have multiple lines and
   bullets!
  - The primary component has:
    - CSS modules
    - Tailwind styles
    - CSS variables
  - To make sure all different CSS channels are working
  */
const Label: React.FC<LabelProps> = ({
  type = 'info', // Set a default
  grayed,
  children,
  required,
  showHelpIcon,
  srOnly,
  className = '',
  helpIconTooltipText,
  tooltipProps,
  ...props
}) => {
  const helpIconAnchorRef = React.useRef(null);
  return (
    <label
      data-testid='label-component'
      className={[classes['label'], 'sui-rounded', srOnly ? classes['sr-only'] : '', className].join(' ')}
      {...props}
    >
      {children}
      {required && <span className='sui-text-negative-text'>*</span>}
      {showHelpIcon && (
        <>
          <Icon className={`${classes['input__helpicon']}`} name='info' size='s' ref={helpIconAnchorRef} />
          {helpIconTooltipText && (
            <Tooltip
              anchorRef={helpIconAnchorRef}
              arrowPosition={ArrowPosition.BOTTOM_LEFT}
              showOnHover
              {...tooltipProps}
            >
              {helpIconTooltipText}
            </Tooltip>
          )}
        </>
      )}
    </label>
  );
};

export default Label;
