import React, { TextareaHTMLAttributes } from 'react';
import classes from './Input.module.scss';
import { InputProps } from './Input.types';
import Icon from '../Icon/Icon';
import InputWrapper from '../InputWrapper/InputWrapper';
import { isSafari, localStringToNumber } from '../../utils';
import IconButton from '../button/IconButton/IconButton';

/**
 * Secondary description on Storybook Docs. I can have multiple lines and
   bullets!
  - The primary component has:
    - CSS modules
    - Tailwind styles
    - CSS variables
  - To make sure all different CSS channels are working

  TO-DO: Add forwardRef just in case parent wants to pass ref to input
  TO-DO: Split types depending on input type
  */
const Input: React.FC<InputProps> = ({
  type,
  label,
  placeholder,
  required,
  disabled,
  name,
  helpText,
  showHelpIcon = false,
  errors,
  errorState,
  onChange,
  onBlur,
  value,
  rows = 8,
  size = 'default',
  inputProps,
  currency,
  maxChars,
  options,
  className = '',
  allowDecimals = true,
  helpIconTooltipText,
  tooltipProps,
  allowCountryCode,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const hasErrors = (errors && errors.length > 0) || errorState;
  const sizeClass = `input__field--${size}`;

  const inputRef = React.useRef<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>(null);

  const [valueLength, setValueLength] = React.useState(0);

  React.useEffect(() => {
    if (inputRef.current) {
      setValueLength(inputRef.current.value.length);
    }
  }, [inputRef]);

  return (
    <>
      <InputWrapper
        label={label}
        required={required}
        name={name}
        helpText={helpText}
        showHelpIcon={showHelpIcon}
        errors={errors}
        className={className}
        helpIconTooltipText={helpIconTooltipText}
        tooltipProps={tooltipProps}
      >
        <>
          {['text', 'email', 'number'].includes(type) && (
            <input
              className={`
            ${classes['input__field']} 
            ${classes[sizeClass]} 
            ${hasErrors ? classes['input__field--error'] : null}
          `}
              id={name}
              name={name}
              data-testid={`input-${name}`}
              type={type}
              placeholder={placeholder}
              value={value}
              disabled={disabled}
              onChange={onChange}
              onBlur={onBlur}
              {...(type === 'number' &&
                !allowDecimals && {
                  onInput: (e) => {
                    const target = e.target as HTMLInputElement;
                    target.value = Math.floor(Number(target.value)).toString();
                  },
                })}
              {...(inputProps as React.InputHTMLAttributes<HTMLInputElement>)}
            />
          )}
          {type === 'password' && (
            <div
              className={`
            ${classes['input__field--password__container']} 
          `}
            >
              <input
                className={`
            ${classes['input__field']} 
            ${classes[sizeClass]} 
            ${hasErrors ? classes['input__field--error'] : null}
          `}
                id={name}
                name={name}
                data-testid={`input-${name}`}
                type={showPassword ? 'text' : 'password'}
                placeholder={placeholder}
                value={value}
                disabled={disabled}
                onChange={onChange}
                onBlur={onBlur}
                aria-label={showPassword ? 'password field with visible text' : 'password field with masked text'}
                {...(inputProps as React.InputHTMLAttributes<HTMLInputElement>)}
              />
              <IconButton
                icon={showPassword ? 'visibility' : 'visibility_off'}
                sentiment='neutral'
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
          )}
          {type === 'phone' && (
            <input
              className={`
              ${classes['input__field']} 
              ${classes[sizeClass]} 
              ${hasErrors ? classes['input__field--error'] : null}
            `}
              id={name}
              name={name}
              data-testid={`phone-${name}`}
              type='tel'
              placeholder={placeholder}
              value={value}
              disabled={disabled}
              onChange={(e) => {
                e.target.value = formatPhoneNumber(e.target.value, allowCountryCode);
                onChange && onChange(e);
              }}
              onBlur={onBlur}
              {...(inputProps as React.InputHTMLAttributes<HTMLInputElement>)}
            />
          )}
          {(type === 'date' || type === 'datetime') && (
            <div
              className={`
              ${classes['input__field--date__container']} 
            `}
            >
              <input
                ref={inputRef as React.RefObject<HTMLInputElement>}
                className={`
              ${classes['input__field']} 
              ${classes['input__field--date']} 
              ${classes[sizeClass]} 
              ${hasErrors ? classes['input__field--error'] : ''}
            `}
                id={name}
                name={name}
                data-testid={`date-${name}`}
                type={`${type}${type === 'datetime' ? '-local' : ''}`}
                placeholder='mm/dd/yyyy'
                value={value}
                disabled={disabled}
                onChange={onChange}
                onBlur={onBlur}
                {...(inputProps as React.InputHTMLAttributes<HTMLInputElement>)}
              />
              {!isSafari() && (
                <IconButton
                  icon='calendar_month'
                  size='small'
                  onClick={() => (inputRef.current as HTMLInputElement).showPicker()}
                  disabled={disabled}
                />
              )}
            </div>
          )}
          {type === 'financial' && (
            <div
              className={`
              ${classes['input__field--financial__container']} 
              ${currency ? classes['input__field--financial__container--has-curency'] : ''} 
            `}
            >
              <Icon className={classes['input__field--financial-icon']} name='attach_money' size='s' />
              <input
                ref={inputRef as React.RefObject<HTMLInputElement>}
                className={`
              ${classes['input__field']} 
              ${classes['input__field--financial']} 
              ${classes[sizeClass]} 
              ${hasErrors ? classes['input__field--error'] : null}
            `}
                id={name}
                name={name}
                data-testid={`financial-${name}`}
                type='number'
                placeholder={placeholder}
                value={value}
                disabled={disabled}
                onChange={onChange}
                onBlur={(e) => {
                  var value = e.target.value;
                  var options = {
                    currency: currency || 'USD',
                    style: 'decimal',
                    currencyDisplay: 'symbol',
                    useGrouping: false,
                    ...(allowDecimals && {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }),
                  };
                  e.target.value =
                    value || value === '0' ? localStringToNumber(value).toLocaleString(undefined, options) : '';
                }}
                onWheel={(e) => (e.target as HTMLInputElement).blur()}
                {...(!allowDecimals && {
                  onInput: (e) => {
                    const target = e.target as HTMLInputElement;
                    target.value = Math.floor(Number(target.value)).toString();
                  },
                })}
                {...(inputProps as React.InputHTMLAttributes<HTMLInputElement>)}
              />

              {currency && <span className={classes['input__field--financial-currency']}>{currency}</span>}
            </div>
          )}
          {type === 'textarea' && (
            <div className={classes['input__field--textarea__container']}>
              <textarea
                className={`
            ${classes['input__field']}
            ${classes['input__field--textarea']}
            ${classes[sizeClass]}
            ${hasErrors ? classes['input__field--error'] : null}
          `}
                id={name}
                name={name}
                data-testid={`textarea-${name}`}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                onChange={(e) => {
                  setValueLength(e.target.value.length);
                  onChange && onChange(e);
                }}
                onBlur={onBlur}
                rows={rows}
                maxLength={maxChars}
                ref={inputRef as React.RefObject<HTMLTextAreaElement>}
                {...(inputProps as TextareaHTMLAttributes<HTMLTextAreaElement>)}
              ></textarea>
              {maxChars && (
                <span className={`${classes['input__charcount']}`}>
                  {valueLength}/{maxChars}
                </span>
              )}
            </div>
          )}
          {type === 'select' && (
            <div className={classes['input__field--select__container']}>
              <select
                className={`
            ${classes['input__field']}
            ${classes['input__field--select']}
            ${classes[sizeClass]}
            ${hasErrors ? classes['input__field--error'] : null}
          `}
                id={name}
                name={name}
                data-testid={`select-${name}`}
                value={value}
                disabled={disabled}
                onChange={(e) => {
                  onChange && onChange(e);
                }}
                onBlur={onBlur}
                ref={inputRef as React.RefObject<HTMLSelectElement>}
                {...(inputProps as React.SelectHTMLAttributes<HTMLSelectElement>)}
              >
                {options?.map((option, i) => (
                  <option value={option.value} key={`${i}-${option.value}`}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )}
        </>
      </InputWrapper>
    </>
  );
};

export default Input;

export function formatPhoneNumber(value: string, allowCountryCode?: boolean) {
  // Remove non-numeric characters and limit to 10 digits or 11 if country code is allowed
  const valueToFormat = value.replace(/\D/g, '').substring(0, allowCountryCode ? 11 : 10);
  let valueToReturn = valueToFormat;
  if (allowCountryCode) {
    const countryCode = valueToFormat.substring(0, 1);
    const areaCode = valueToFormat.substring(1, 4);
    const middle = valueToFormat.substring(4, 7);
    const last = valueToFormat.substring(7, 11);

    if (valueToFormat.length > 7) {
      valueToReturn = `+${countryCode} (${areaCode}) ${middle}-${last}`;
    } else if (valueToFormat.length > 4) {
      valueToReturn = `+${countryCode} (${areaCode}) ${middle}`;
    } else if (valueToFormat.length > 1) {
      valueToReturn = `+${countryCode} (${areaCode}`;
    } else if (valueToFormat.length > 0) {
      valueToReturn = `+${countryCode}`;
    } else {
      valueToReturn = '';
    }
  } else {
    const areaCode = valueToFormat.substring(0, 3);
    const middle = valueToFormat.substring(3, 6);
    const last = valueToFormat.substring(6, 10);

    if (valueToFormat.length > 6) {
      valueToReturn = `${areaCode}-${middle}-${last}`;
    } else if (valueToFormat.length > 3) {
      valueToReturn = `${areaCode}-${middle}`;
    } else if (valueToFormat.length > 0) {
      valueToReturn = `${areaCode}`;
    } else {
      valueToReturn = '';
    }
  }
  return valueToReturn;
}
