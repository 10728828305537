import React from 'react';
import { ArrowPosition, TooltipProps } from './Tooltip.types';
import classes from './Tooltip.module.scss';
import TooltipArrow from '../../assets/Icons/TooltipArrow';
import LabelButton from '../button/LabelButton/LabelButton';
import { useTooltipPositionHelper } from './hooks';

/**
 * Secondary description on Storybook Docs. I can have multiple lines and
   bullets!
  - The primary component has:
    - CSS modules
    - Tailwind styles
    - CSS variables
  - To make sure all different CSS channels are working
  */
const Tooltip: React.FC<TooltipProps> = ({
  arrowPosition = ArrowPosition.TOP_LEFT,
  anchorRef,
  isVisible,
  onVisibilityChange,
  showOnHover,
  showDismissButton,
  children,
  ...props
}) => {
  const tooltipRef = React.useRef(null);

  const { includeTooltip, setIncludeTooltip } = useTooltipPositionHelper({
    isVisible,
    arrowPosition,
    anchorRef,
    tooltipRef,
    showOnHover,
    onVisibilityChange,
    showDismissButton,
  });

  const additonalClassNames = props.className ? props.className : '';

  return (
    <>
      {includeTooltip && (
        <div
          data-testid='tooltip'
          className={`${classes['tooltip']} ${
            includeTooltip ? `${classes['tooltip--visible']}` : ''
          } ${additonalClassNames}`}
          ref={tooltipRef}
          {...props}
        >
          <TooltipArrow
            className={`${classes['tooltip__arrow']} ${classes[`tooltip__arrow--${arrowPosition.replace(/ /g, '-')}`]}`}
          />
          <div
            className={`${classes['tooltip__content']} ${
              classes[`tooltip__content--${arrowPosition.replace(/ /g, '-')}`]
            }`}
          >
            {children}
            {showDismissButton && (
              <div className={`${classes['tooltip__footer']}`}>
                <LabelButton
                  data-testid='dismiss-button'
                  onClick={() => setIncludeTooltip(false)}
                  labelText='Got it'
                  variantType='tertiary'
                  size='small'
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Tooltip;
