import * as React from 'react';
import { SVGProps } from 'react';
const TooltipArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg width='15' height='7' viewBox='0 0 15 7' fill='none' {...props}>
    <path
      d='M5.55915 0.699632C6.35533 -0.202711 7.76231 -0.20271 8.5585 0.699632L14.1176 7H0L5.55915 0.699632Z'
      fill='#F2F2F2'
    />
  </svg>
);
export default TooltipArrow;
