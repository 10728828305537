import React from 'react';
import classes from './InputWrapper.module.scss';
import { InputWrapperProps } from './InputWrapper.types';
import Icon from '../Icon/Icon';
import Label from '../Label/Label';
import HelpText from '../HelpText/HelpText';

/**
 * Secondary description on Storybook Docs. I can have multiple lines and
   bullets!
  - The primary component has:
    - CSS modules
    - Tailwind styles
    - CSS variables
  - To make sure all different CSS channels are working
  */
const InputWrapper: React.FC<InputWrapperProps> = ({
  label,
  required,
  name,
  helpText,
  showHelpIcon = false,
  errors,
  children,
  className = '',
  helpIconTooltipText,
  tooltipProps,
}) => {
  const hasErrors = errors && errors.length > 0;

  return (
    <div data-testid={`${name}-input`} className={`${classes['input__container']} ${className}`}>
      {label && (
        <Label htmlFor={name} {...{ required, showHelpIcon, helpIconTooltipText, tooltipProps }}>
          {label}
        </Label>
      )}
      {children}
      <div className={`${classes['input__field--footer']}`}>
        <div>
          {helpText && !errors && <HelpText>{helpText}</HelpText>}
          {hasErrors &&
            errors.map((error) => (
              <span key={`${name}-${error}`} className={`${classes['input__error']}`}>
                {error}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};

export default InputWrapper;
